import React, { createContext, useContext, ReactNode } from 'react'
import { useAuth0 } from "@auth0/auth0-react";

interface PermissionsProviderProps {
    children: ReactNode
}

export enum Permissions {
    // Suppliers
    READ_SUPPLIERS = "read:suppliers",
    UPDATE_SUPPLIERS = "update:suppliers",

    // Inbound Forecasts
    READ_INBOUND_FORECASTS = "read:inbound-forecasts",
    CANCEL_INBOUND_FORECASTS = "cancel:inbound-forecasts",
    POSTPONE_INBOUND_FORECASTS = "postpone:inbound-forecasts",
    RESOLVE_ERROR_EVENT_INBOUND_FORECASTS = "resolve-error-event:inbound-forecasts",

    // Purchase Orders
    READ_PURCHASE_ORDERS = "read:purchase-orders",
    CREATE_PURCHASE_ORDERS = 'update:purchase-orders',
    UPDATE_REQUISITIONS = "update:requisitions",
    MARK_AS_SUBMITTED_PURCHASE_ORDERS = "mark-as-submitted:purchase-orders",
    RESOLVE_ERROR_EVENT_PURCHASE_ORDERS = "resolve-error-event:purchase-orders",

    // Sales Orders
    READ_SALES_ORDERS = "read:sales-orders",
    CANCEL_SALES_ORDERS = "cancel:sales-orders",
    RESOLVE_ERROR_EVENT_SALES_ORDERS = "resolve-error-event:sales-orders",
    UNDO_CONSOLIDATION = "execute:undo-consolidation",

    // Inventory
    READ_STOCKS = "read:stocks",

    // RMAs
    READ_RMAS = "read:rmas",

    // Utils
    READ_RATE_LIMITS = "read:rate-limits",
    READ_ERROR_EVENT_PAYLOAD = "read:error-event-payload" // The payload is already included in the error event, but this permission is used to show/hide the payload in the UI
}

export const CLAIMS_NAMESPACE = "https://claims.proforto.app/";
export const PERMISSIONS_CLAIM = `${CLAIMS_NAMESPACE}permissions`;

const PermissionsContext = createContext({
    isAllowed: (permission: Permissions) => false as boolean,
    userPermissions: [] as Permissions[],
    Permissions
})

export const usePermissions = () => {
    const context = useContext(PermissionsContext)
    if (context === undefined) {
        throw new Error('usePermissions must be used within an PermissionsProvider')
    }
    return context
}

export const PermissionsProvider: React.FC<PermissionsProviderProps> = ({ children }) => {
    const { user } = useAuth0();

    const userPermissions = (user?.[PERMISSIONS_CLAIM] ?? []) as Permissions[];
    const isAllowed = (permission: Permissions) => userPermissions.includes(permission);

    return (
        <PermissionsContext.Provider value={{ isAllowed, Permissions, userPermissions }}>
            {children}
        </PermissionsContext.Provider>
    )
}
