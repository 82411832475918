import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useApiClient } from 'Contexts/ApiClientContext';
import {
    Button,
    Card,
    Loading,
    SectionTitle,
    PageHeader,
    PageSettings,
    PrinterSelector,
    Table,
    Thead,
    Tr, Th, Tbody, Td, TextInput
} from 'Components';
import {
    Address,
    SalesOrder,
    SalesOrderItem,
    addressEquals,
    personalisationTypeEnumToLabel, SalesOrderView
} from "types/sales-order";
import { MontaOrder, MontaOriginEnum } from "types/sales-order/montaOrder";
import Accordion from "Components/Library/Accordion";
import Modal from "Components/Library/Modal";
import MontaOrderCard from "Components/SalesOrders/MontaOrder";
import SalesOrderLinesDetail from "Components/SalesOrders/SalesOrderLinesDetail";
import { CancelLineItem } from "utils/apiClient";
import { useAuth0 } from "@auth0/auth0-react";
import { Permissions, useMessage, usePermissions } from 'Contexts';
import StoreLogo from "Components/SalesOrders/StoreLogo";
import ErrorEvents from "Components/ErrorEvents";
import { Rma } from "../types/rma";
import { useClickableNavigate } from "../utils/PathConstants";
import { canCancelOrder } from "../utils/montaConstants";
import Select from "../Components/Library/Form/Select";
import { reasonOtherConstant, returnReasons } from "../utils/customerServiceConstants";
import { Checkbox } from "../Components";
import {Tooltip} from "@material-tailwind/react";
import {IoLeafSharp} from "react-icons/io5";
import {formatToDutchDateTime, formatToDutchDateTimeFromUtc} from "../utils/dates";

export const displayPersoDetails = (line: SalesOrderItem | null) => {
    if (!line || !line.personalisation) return '';

    return `${personalisationTypeEnumToLabel(line.personalisation.type)}: ${line.personalisation.positions.map(pos => pos.name).join(', ')} `;
}

export default function SalesOrderDetail() {
    let { mageReference } = useParams();
    const apiClient = useApiClient();

    const {
        user,
    } = useAuth0();
    const { isAllowed } = usePermissions();
    const { handleError } = useMessage();

    const [salesOrder, setSalesOrder] = useState<SalesOrder | null>(null)
    const [montaOrders, setMontaOrders] = useState<MontaOrder[] | null>(null)
    const [rmas, setRmas] = useState<Rma[] | null>(null)
    const [selectedPrinter, setSelectedPrinter] = useState<number | null>(parseInt(localStorage.getItem('selectedInboundBarcodePrinter') ?? ''))
    const [billingSameAsShippingAddress, setBillingSameAsShippingAddress] = useState<boolean>(false);
    const [selectedShippingLabelAddress, setSelectedLabelAddress] = useState<Address | null>(null);
    const [hasShippedMontaOrders, setHasShippedMontaOrders] = useState<boolean>(false);
    const [selectedCancellationOrders, setSelectedCancellationOrders] = useState<string[]>([]);

    const [cancelationReason, setCancelationReason] = useState<string>(reasonOtherConstant);
    const [cancelationReasonOther, setCancelationReasonOther] = useState<string>("");

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showErrorLog, setShowErrorLog] = useState(false);
    const [showPrintNewShippingLabel, setShowPrintNewShippingLabel] = useState(false);

    const getCancellableMontaOrders = () => {
        return montaOrders?.filter(montaOrder => canCancelOrder(montaOrder)) ?? [];
    }

    const {
        onMouseDownHandler,
        onMouseMoveHandler,
        navigateToRma
    } = useClickableNavigate();

    const toggleOrderCancellationSelection = (webshopOrderId: string) => {
        setSelectedCancellationOrders((prevSelected) =>
            prevSelected.includes(webshopOrderId)
                ? prevSelected.filter((id) => id !== webshopOrderId) // Deselect
                : [...prevSelected, webshopOrderId] // Select
        );
    };

    const handleCancelOrders = async () => {
        if (!user) return; // Should not happen
        if (!user.email) {
            console.error("User does not have an email address, cannot cancel orders");
            return;
        }

        if (selectedCancellationOrders.length === 0) {
            console.error("No orders selected to cancel");
            handleCancelClosed();
            return;
        }

        if (cancelationReason === reasonOtherConstant && cancelationReasonOther === "") {
            console.error("No other reason filled in");
            handleError("Please fill in the other reason");
            return;
        }

        const author = user.email;
        const { data } = await apiClient.cancelOrders(
            selectedCancellationOrders,
            author,
            reasonOtherConstant === cancelationReason
                ? cancelationReasonOther
                : cancelationReason
        );

        refreshSalesOrder(data);
        setCancelationReason(reasonOtherConstant);
        setCancelationReasonOther("");
        handleCancelClosed();
    };

    const handleCancellation = async (webshopOrderId: string, reason: string, lineItem?: CancelLineItem) => {
        if (!user) return; // Should not happen
        if (!user.email) {
            console.error("User does not have an email address, cannot cancel line item");
            return;
        }

        const author = user.email;
        const { data } = await apiClient.cancelLineItem(webshopOrderId, author, reason, lineItem);
        refreshSalesOrder(data);
    }

    const handleUndoConsolidation = async () => {
        if (!user || !salesOrder) return; // Should not happen
        if (!user.email) {
            console.error("User does not have an email address, cannot cancel line item");
            return;
        }

        const author = user.email;
        const {data} = await apiClient.undoMontaOrderConsolidation(salesOrder?.reference.mage, author);

        refreshSalesOrder(data);
    }

    const hasUndoConsolidationOccurred = (montaOrders?.filter(mo => mo.origin !== MontaOriginEnum.Personalisation && mo.webshopOrderId.includes('-')).length ?? 0) > 0;

    // Mike easter egg
    const cancelOrdersLabel = user?.email?.toLowerCase() === "mike.bolder@proforto.nl"
        && Math.random() < 0.20 // 20% chance
        ? "Annuleer heel de kutzooi maar"
        : "Cancel";

    const refreshSalesOrder = (data: SalesOrderView | null) => {
        if (data) {
            setSalesOrder(data.salesOrder);
            setMontaOrders(data.montaOrders);
            setRmas(data.rmas);
            setSelectedLabelAddress(data.salesOrder.shipping.address);
            setHasShippedMontaOrders(data.montaOrders?.some(mo => mo.shipped));
            setSelectedCancellationOrders(data.montaOrders?.filter(mo => canCancelOrder(mo)).map(mo => mo.webshopOrderId) ?? []);

            if (addressEquals(data.salesOrder.billing.address, data.salesOrder.shipping.address)) {
                setBillingSameAsShippingAddress(true);
            }
        }
    }

    useEffect(() => {
        const fetchSalesOrder = async () => {
            if (!mageReference) return;
            try {
                const { data } = await apiClient.getSalesOrderView(mageReference);
                refreshSalesOrder(data);
            } catch (error) {
                console.error("Error fetching sales order:", error);
            }
        };

        fetchSalesOrder();
    }, [apiClient, mageReference]);

    const handleCancelClicked = () => {
        setShowCancelModal(true);
    }

    const handleErrorLogClicked = () => {
        setShowErrorLog(true);
    };

    const handlePrintNewShippingLabelClicked = () => {
        setShowPrintNewShippingLabel(true);
    }

    const handleCancelClosed = () => {
        setShowCancelModal(false);
    }

    const handleErrorLogClosed = () => {
        setShowErrorLog(false);
    }

    const handlePrintNewShippingLabelClosed = () => {
        setShowPrintNewShippingLabel(false);
    }

    const handlePrintNewShippingLabelSubmit = async () => {
        if (selectedPrinter === null) {
            handleError('No printer selected');
            return;
        }
        await apiClient.printNewShippingLabel(salesOrder!.reference.mage, selectedShippingLabelAddress, selectedPrinter);
        handlePrintNewShippingLabelClosed();
    }

    const handleResolveErrorItem = async (errorId: string) => {
        if (!salesOrder) return;
        if (!user) return; // Should not happen
        if (!user.email) {
            console.error("User does not have an email address, cannot resolve");
            return;
        }

        const updatedEvent = await apiClient.resolveSalesOrderErrorEvent(salesOrder.reference.mage, errorId, user.email);
        if (!updatedEvent) return;


        const eventIndex = salesOrder.errorEvents.findIndex(event => event.id === updatedEvent!.id);

        if (eventIndex === -1) return;

        const updatedErrorEvents = [...salesOrder.errorEvents];
        updatedErrorEvents[eventIndex] = updatedEvent;

        // Use setSalesOrder to update the salesOrder state with the new errorEvents array
        setSalesOrder({ ...salesOrder, errorEvents: updatedErrorEvents });
    };

    const handleResolveAllErrors = async () => {
        if (!salesOrder) return;
        if (!user) return; // Should not happen
        if (!user.email) {
            console.error("User does not have an email address, cannot resolve");
            return;
        }

        const updatedSalesOrder = await apiClient.resolveAllSalesOrderErrorEvents(salesOrder.reference.mage, user.email);
        if (!updatedSalesOrder) return;

        setSalesOrder({ ...salesOrder, errorEvents: updatedSalesOrder.errorEvents });
        setShowErrorLog(false);
    }

    if (salesOrder === null) return (
        <Loading />
    )

    const amountOfErrors = salesOrder.errorEvents.filter(e => !e.isResolved).length;

    const renderOrderLinesTitle = () => {
        return (
            <>
                {salesOrder.flags.isConsolidatedShipping && (
                    <Tooltip content="Consolidated Shipping">
                        <div className="inline-block mr-1">
                            <IoLeafSharp className="inline-block mr-1 text-green-500 h-4 w-4"/>
                        </div>
                    </Tooltip>
                )}
                <span>Order Lines</span>
            </>
        )
    }

    return (
        <>
            <PageHeader
                title={(
                    <>
                        <div className="flex items-center gap-2">
                            <StoreLogo store={salesOrder.store} />
                            {salesOrder.reference.mage}
                        </div>
                    </>
                )}
                documentTitle={`#${salesOrder.reference.mage}`}
                metaData={[
                    salesOrder.reference.bol && (
                        `Bol: ${salesOrder.reference.bol}`
                    ),
                    `Created: ${formatToDutchDateTimeFromUtc(salesOrder.meta.createdAt)}`
                ]}
                toolbar={<>
                    {hasShippedMontaOrders && (
                        <Button onClick={handlePrintNewShippingLabelClicked} type="cta">
                            Print New Shipping Label
                        </Button>
                    )}
                    {isAllowed(Permissions.CANCEL_SALES_ORDERS) && montaOrders?.some(montaOrder => canCancelOrder(montaOrder)) && (
                        <Button onClick={handleCancelClicked} type="red">
                            Cancel
                        </Button>
                    )}
                    <Button onClick={handleErrorLogClicked} type="black">
                        Error Log {amountOfErrors > 0 && `(${amountOfErrors})`}
                    </Button>
                    {hasShippedMontaOrders && <PageSettings>
                        <b>Labelprinter</b>
                        <PrinterSelector
                            storageKey="selectedNewShippingLabelPrinter"
                            selectedPrinter={selectedPrinter ?? 0}
                            setSelectedPrinter={setSelectedPrinter}
                        />
                    </PageSettings>
                    }
                </>}
            />

            {showCancelModal && isAllowed(Permissions.CANCEL_SALES_ORDERS) && getCancellableMontaOrders().length > 0 && (
                <Modal title={cancelOrdersLabel} onClose={handleCancelClosed} showBackdrop closeWithEscape>
                    <div className="space-y-4">
                        <h2>Select which Monta Orders to cancel</h2>
                        <div className="space-y-4 bg-gray-100 rounded-md shadow-md border">
                            {getCancellableMontaOrders().map((montaOrder, index) => (
                                <div key={index}
                                    className="flex items-center gap-4 pl-4 p-2 hover:bg-gray-300 cursor-pointer rounded-md"
                                    onClick={() => toggleOrderCancellationSelection(montaOrder.webshopOrderId)}
                                >
                                    <Checkbox
                                        checked={selectedCancellationOrders.includes(montaOrder.webshopOrderId)}
                                        onChange={() => {}}
                                    />
                                    <label htmlFor={`cancel-monta-order-${index}`}>
                                        <span>{montaOrder.webshopOrderId}</span>
                                    </label>
                                </div>
                            ))}
                        </div>
                        <span className='block mt-2'>Reason</span>
                        <Select
                            value={cancelationReason}
                            onChange={setCancelationReason}
                            options={[{
                                value: cancelationReasonOther,
                                label: "Other"
                            },
                            ...returnReasons
                            ]}
                        />

                        {cancelationReason === reasonOtherConstant && (
                            <div>
                                <span className='block mt-2'>Other Reason</span>
                                <TextInput
                                    value={cancelationReasonOther}
                                    onChange={setCancelationReasonOther}
                                    className="mt-2"
                                    maxLength={100}
                                    required={true}
                                />
                            </div>
                        )}
                        <div className="flex justify-end gap-4">
                            <Button onClick={handleCancelClosed} type="white">Close</Button>
                            <Button onClick={handleCancelOrders} type="red">Cancel Selected Orders</Button>
                        </div>
                    </div>
                </Modal>
            )}

            {showErrorLog && (
                <Modal title="Error Log" onClose={handleErrorLogClosed} showBackdrop closeWithEscape>
                    <ErrorEvents
                        errorEvents={salesOrder.errorEvents || []}
                        allowedToResolve={isAllowed(Permissions.RESOLVE_ERROR_EVENT_SALES_ORDERS)}
                        resolveErrorEvent={handleResolveErrorItem}
                        resolveAllErrorEvents={handleResolveAllErrors}
                    />
                </Modal>
            )}

            {showPrintNewShippingLabel && (
                <Modal
                    title="Print New Shipping Label"
                    onClose={handlePrintNewShippingLabelClosed}
                    showBackdrop
                    closeWithEscape
                >
                    <div>
                        {!selectedPrinter
                            ? (<p>A printer must be selected through the settings (gear icon)</p>)
                            : billingSameAsShippingAddress
                                ? (<p> Are you sure you want to print a new shipping label?</p>)
                                : (
                                    <div>
                                        <p className="my-1">Which address should be used?</p>
                                        <div className="flex gap-4 text-gray-600 whitespace-nowrap">
                                            <div
                                                className="flex-1 py-6 pr-6 pl-5 border border-gray-300 rounded-md flex items-start">
                                                <input
                                                    type="radio"
                                                    id="billingAddress"
                                                    name="addressOption"
                                                    className="mr-3 mt-1.5"
                                                    onClick={() => setSelectedLabelAddress(salesOrder.billing.address)}
                                                />
                                                <label htmlFor="billingAddress"
                                                    className="cursor-pointer flex-shrink-0">
                                                    <h3 className="font-semibold mb-1 text-gray-800">Billing
                                                        Address</h3>
                                                    <p className="text-sm">
                                                        {salesOrder.billing.address.street} {salesOrder.billing.address.houseNumber}
                                                        {salesOrder.billing.address.houseNumberAddition ? ` ${salesOrder.billing.address.houseNumberAddition}` : ''}<br />
                                                        {salesOrder.billing.address.postalCode} {salesOrder.billing.address.city}<br />
                                                        {salesOrder.billing.address.countryCode}
                                                    </p>
                                                </label>
                                            </div>
                                            <div
                                                className="flex-1 py-6 pr-6 pl-5 border border-gray-300 rounded-md flex items-start">
                                                <input
                                                    type="radio"
                                                    id="shippingAddress"
                                                    name="addressOption"
                                                    className="mr-3 mt-1.5"
                                                    onClick={() => setSelectedLabelAddress(salesOrder.shipping.address)}
                                                    defaultChecked
                                                />
                                                <label htmlFor="shippingAddress" className="cursor-pointer">
                                                    <h3 className="font-semibold mb-1 text-gray-800">Shipping
                                                        Address</h3>
                                                    <p className="text-sm">
                                                        {salesOrder.shipping.address.street} {salesOrder.shipping.address.houseNumber}
                                                        {salesOrder.shipping.address.houseNumberAddition ? ` ${salesOrder.shipping.address.houseNumberAddition}` : ''}<br />
                                                        {salesOrder.shipping.address.postalCode} {salesOrder.shipping.address.city}<br />
                                                        {salesOrder.shipping.address.countryCode}
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )}

                        <div className="flex justify-end gap-4 mt-6">
                            <Button onClick={handlePrintNewShippingLabelClosed} type="white">Cancel</Button>
                            <Button onClick={handlePrintNewShippingLabelSubmit} type="cta">Print</Button>
                        </div>
                    </div>
                </Modal>
            )}

            <div className="flex gap-6">
                <Card className="flex-none w-58 h-[35rem] sticky top-6">
                    <span className="block">Payment Method
                        <span className="block text-gray-600">{salesOrder.billing.paymentMethod}</span>
                    </span>
                    <SectionTitle>Customer</SectionTitle>
                    <div>

                        <span>Name</span>
                        <span className="text-gray-600 block">
                            {salesOrder.customer.firstName} {salesOrder.customer.lastName}
                        </span>

                        <hr className="mt-2 mb-2" />
                        <span className="block">Billing Address</span>
                        <span className="block text-gray-600">
                            {salesOrder.billing.address.companyName && (<>{salesOrder.billing.address.companyName}<br /></>)}
                            {salesOrder.billing.address.street} {salesOrder.billing.address.houseNumber} {salesOrder.billing.address.houseNumberAddition ?? ''}<br />
                            {salesOrder.billing.address.postalCode} {salesOrder.billing.address.city} ({salesOrder.billing.address.countryCode})
                        </span>

                        <span className="block mt-2">Shipping Address</span>
                        <span className="block text-gray-600">
                            {salesOrder.shipping.pickupLocationCode && (
                                <span className="block">Service Point: {salesOrder.shipping.pickupLocationCode}</span>
                            )}
                            {salesOrder.shipping.address.companyName && (<>{salesOrder.shipping.address.companyName}<br /></>)}
                            {salesOrder.shipping.address.street} {salesOrder.shipping.address.houseNumber} {salesOrder.shipping.address.houseNumberAddition ?? ''}<br />
                            {salesOrder.shipping.address.postalCode} {salesOrder.shipping.address.city} ({salesOrder.shipping.address.countryCode})
                        </span>

                        <hr className="mt-2 mb-2" />
                        <span className="block">Email</span>
                        <span className="text-gray-600 block"> {salesOrder.customer.email}</span>

                        <span className="block mt-2">Phone number</span>
                        <span className="text-gray-600 block"> {salesOrder.customer.phoneNumber}</span>
                    </div>
                </Card>

                <div className="flex flex-col w-full gap-y-6">
                    <Accordion
                        title={renderOrderLinesTitle()}
                        openByDefault
                    >
                        <SalesOrderLinesDetail salesOrder={salesOrder}/>
                    </Accordion>

                    <Accordion title="Monta fulfillments" openByDefault>
                        <div className="space-y-4">
                            {montaOrders?.map((montaOrder, index) => (
                                <div key={index}>
                                    <MontaOrderCard
                                        salesOrder={salesOrder}
                                        order={montaOrder}
                                        handleCancellation={handleCancellation}
                                        handleUndoConsolidation={handleUndoConsolidation}
                                        hasUndoConsolidationOccurred={hasUndoConsolidationOccurred}
                                    />
                                </div>
                            ))}

                            {montaOrders?.length === 0 && (
                                <div>
                                    No Monta fulfillments found
                                </div>
                            )}
                        </div>
                    </Accordion>

                    <Accordion title="RMAs" openByDefault>
                        <div className="space-y-4">
                            {rmas?.length === 0
                                ? (
                                    <div>
                                        No RMAs found
                                    </div>
                                )
                                : (
                                    <Table>
                                        <Thead>
                                            <Tr>
                                                <Th>RmaId</Th>
                                                <Th>Webshop Order Id</Th>
                                                <Th>Fully Refunded</Th>
                                                <Th>Quantity Forecasted</Th>
                                                <Th>Quantity Received</Th>
                                                <Th>Lines</Th>
                                                <Th>Registered At</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {rmas?.map(rma => (
                                                <Tr>
                                                    <Td>
                                                        <span
                                                            className="hover:underline cursor-pointer"
                                                            onMouseDown={onMouseDownHandler}
                                                            onMouseMove={onMouseMoveHandler}
                                                            onClick={(e) => navigateToRma(e, rma.rmaId)}
                                                        >
                                                            {rma.rmaId}
                                                        </span>
                                                    </Td>
                                                    <Td>{rma.webshopOrderId}</Td>
                                                    <Td>{rma.refundEvents.length > 0 && rma.refundEvents.every(refundEvent => refundEvent.isRefunded)
                                                        ? <span
                                                            className="bg-primary text-white text-sm font-medium mr-2 px-2 py-0.5 rounded ms-3">Yes</span>
                                                        : <span
                                                            className="bg-red-400 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ms-3">No</span>
                                                    }</Td>
                                                    <Td>{rma.lines.reduce((totalQuantity, line) => totalQuantity + line.forecastedQuantity, 0)}</Td>
                                                    <Td>{rma.lines.reduce((totalQuantity, line) => totalQuantity + line.returnedQuantity, 0)}</Td>
                                                    <Td className="whitespace-pre-line">{rma.lines
                                                        .map(line =>
                                                            `${(rma.lines.length > 1 ? `Forecasted: ${line.forecastedQuantity ?? 0}x | Received: ${line.returnedQuantity}x | SKU: ` : '')}${line.product.sku}`
                                                        ).join('\n')
                                                    }</Td>
                                                    <Td>{formatToDutchDateTime(rma.registeredAt)}</Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                )}
                        </div>
                    </Accordion>
                </div>
            </div>
        </>
    );
}
