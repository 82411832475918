import { Rma } from "types/rma";
import { Table, Tbody, Td, Th, Thead, Tr } from "Components/Library/Table";
import { useClickableNavigate } from "utils/PathConstants";
import {formatToDutchDateTime} from "../../utils/dates";

export default function RmaTable({ rmas }: { rmas: Rma[] }) {
    const {
        onMouseDownHandler,
        onMouseMoveHandler,
        navigateToRma
    } = useClickableNavigate();

    return (
        <>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Order #</Th>
                        <Th>Rma Id</Th>
                        <Th>Email</Th>
                        <Th>All Accepted</Th>
                        <Th>All Refunded</Th>
                        <Th className="text-right">Lines</Th>
                        <Th className="text-right">Registered At</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rmas.length === 0 && (
                        <Tr>
                            <Td colSpan={6}>No RMAs found</Td>
                        </Tr>
                    )}
                    {rmas.map((rma, index) => (
                        <Tr
                            key={index}
                            className="cursor-pointer hover:bg-gray-200"
                            onMouseDown={onMouseDownHandler}
                            onMouseMove={onMouseMoveHandler}
                            onClick={(e) => navigateToRma(e, rma.rmaId)}
                        >
                            <Td>{rma.reference.mage}</Td>
                            <Td>{rma.rmaId}</Td>
                            <Td>{rma.customerEmail}</Td>
                            <Td>{rma.lines.every(line => line.isSellable === null) ? '' : (rma.lines.every(line => line.isSellable))
                                ? <span className="bg-primary text-white text-sm font-medium mr-2 px-2 py-0.5 rounded ms-3">Yes</span>
                                : <span className="bg-red-400 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ms-3">No</span>}</Td>
                            <Td>{rma.refundEvents.length === 0 ? '' : (rma.refundEvents.every(event => event.isRefunded)
                                ? <span className="bg-primary text-white text-sm font-medium mr-2 px-2 py-0.5 rounded ms-3">Yes</span>
                                : <span className="bg-red-400 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ms-3">No</span>)}</Td>
                            <Td className="text-right">{rma.lines.length}</Td>
                            <Td className="text-right">{formatToDutchDateTime(rma.registeredAt)}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    );
}
