import { SalesOrder } from 'types/sales-order';
import { Tooltip } from "@material-tailwind/react";
import { FaPaintBrush } from 'react-icons/fa';
import { MdDiscount } from 'react-icons/md';
import { displayPersoDetails } from 'Pages/SalesOrderDetail';
import { display } from 'utils/pricing';
import { formatToDutchDate } from 'utils/dates';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td } from 'Components';
import { useClickableNavigate } from 'utils/PathConstants';


type SalesOrderDetailProps = {
    salesOrder: SalesOrder;
}

const SalesOrderLinesDetail = ({ salesOrder }: SalesOrderDetailProps) => {
    const {
        onMouseDownHandler,
        onMouseMoveHandler,
        navigateToSku,
    } = useClickableNavigate();

    return (
        <Table>
            <Thead>
                <Tr>
                    <Th>Name</Th>
                    <Th>Sku</Th>
                    <Th>EAN</Th>
                    <Th>Estimated</Th>
                    <Th className="text-right">Qty</Th>
                    <Th className="text-right">Row Subtotal</Th>
                </Tr>
            </Thead>
            <Tbody>
                {salesOrder.items.length === 0 && (
                    <Tr>
                        <Td colSpan={5}>
                            No items found
                        </Td>
                    </Tr>
                )}
                {salesOrder.items.map((line, index) => (
                    <Tr key={index}>
                        <Td>
                            {line.personalisation && (
                                <Tooltip content={displayPersoDetails(line)}>
                                    <div className="inline-block mr-1 hover:text-black">
                                        <FaPaintBrush />
                                    </div>
                                </Tooltip>
                            )}
                            {line.isPromo && (
                                <Tooltip content="Is Promo item">
                                    <div className="inline-block mr-1 hover:text-black">
                                        <MdDiscount />
                                    </div>
                                </Tooltip>
                            )}
                            {line.product.name}
                        </Td>
                        <Td>
                            <div
                                className="hover:underline cursor-pointer"
                                onMouseDown={onMouseDownHandler}
                                onMouseMove={onMouseMoveHandler}
                                onClick={(e) => navigateToSku(e, line.product.sku)}
                            >
                                {line.product.sku}
                            </div>
                        </Td>
                        <Td>{line.product.ean ?? "-"}</Td>
                        <Td>
                            <span>
                                {!line.personalisation && (
                                    <>
                                        {line.forecastedDeliveryDate?.warehouse?.errorMessage ? (
                                            <span className="block text-red-500">
                                                {line.forecastedDeliveryDate?.warehouse?.errorMessage}
                                            </span>
                                        ) : line.forecastedDeliveryDate?.warehouse?.date ? (
                                            <span className="block">
                                                {(line.forecastedDeliveryDate?.personalisation || line.forecastedDeliveryDate?.purchase)  && `${line.forecastedDeliveryDate?.warehouse?.amount}x`}
                                                {formatToDutchDate(line.forecastedDeliveryDate?.warehouse?.date)}
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </span>
                            <span>
                                {line.forecastedDeliveryDate?.purchase?.errorMessage ? (
                                    <span className="block text-red-500">
                                        {line.forecastedDeliveryDate?.purchase?.errorMessage}
                                    </span>
                                ) : line.forecastedDeliveryDate?.purchase?.date ? (
                                    <span className="block">
                                        {(line.forecastedDeliveryDate?.personalisation || line.forecastedDeliveryDate?.warehouse) && `${line.forecastedDeliveryDate?.purchase?.amount}x`}
                                        {formatToDutchDate(line.forecastedDeliveryDate?.purchase?.date)}
                                    </span>
                                ) : null}
                            </span>
                            <span>
                                {!!line.personalisation && line.forecastedDeliveryDate?.personalisation && (
                                    <>
                                        {line.forecastedDeliveryDate?.personalisation.printing?.errorMessage ? (
                                            <span className="block text-red-500">
                                                {line.forecastedDeliveryDate?.personalisation.printing?.errorMessage}
                                            </span>
                                        ) : line.forecastedDeliveryDate?.personalisation?.printing?.date ? (
                                            <span className="block">
                                                {`${line.forecastedDeliveryDate?.personalisation?.printing?.amount}`}
                                                x {formatToDutchDate(line.forecastedDeliveryDate?.personalisation?.printing?.date)}
                                            </span>
                                        ) : null}

                                        {line.forecastedDeliveryDate?.personalisation.embroidery?.errorMessage ? (
                                            <span className="block text-red-500">
                                                {line.forecastedDeliveryDate?.personalisation.embroidery?.errorMessage}
                                            </span>
                                        ) : line.forecastedDeliveryDate?.personalisation?.embroidery?.date ? (
                                            <span className="block">
                                                {`${line.forecastedDeliveryDate?.personalisation?.embroidery?.amount}`}
                                                x {formatToDutchDate(line.forecastedDeliveryDate?.personalisation?.embroidery?.date)}
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </span>
                        </Td>
                        <Td className="text-right">{line.quantity}</Td>
                        <Td className="text-right">{display(line.price.value * line.quantity)}</Td>
                    </Tr>
                ))}
            </Tbody>

            <Tfoot>
                <Tr>
                    <Td colSpan={5} />
                    <Td className="w-40 text-right">
                        <span className="block">
                            <strong>Discount:</strong> {display(salesOrder.billing.price.discount.value)}
                        </span>
                        <span className="block">
                            <strong>Total:</strong> {display(salesOrder.billing.price.totalPrice.value)}
                        </span>
                    </Td>
                </Tr>
            </Tfoot>
        </Table>
    );
};

export default SalesOrderLinesDetail;
