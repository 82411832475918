import { InboundForecastGroup } from "types/inbound-forecast-group";
import { Table, Thead, Tbody, Tr, Th, Td } from "Components";
import { PiWarningFill } from "react-icons/pi";
import { Tooltip } from "@material-tailwind/react";
import { useClickableNavigate } from "utils/PathConstants";
import {formatToDutchDateTime} from "../../utils/dates";

export default function InboundForecastGroupTable({ inboundForecastGroups }: { inboundForecastGroups: InboundForecastGroup[] }) {
    const {
        onMouseDownHandler,
        onMouseMoveHandler,
        navigateToInboundForecastGroup,
    } = useClickableNavigate();

    return (
        <Table>
            <Thead>
                <Tr>
                    <Th>PO Reference</Th>
                    <Th>Supplier</Th>
                    <Th className="text-right">
                        Number of items
                    </Th>
                    <Th className="text-right">
                        Created
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {inboundForecastGroups.length === 0 && (
                    <Tr>
                        <Td colSpan={5}>
                            No Inbound Forecast Groups found
                        </Td>
                    </Tr>
                )}
                {inboundForecastGroups.map((inboundForecastGroup, index) => (
                    <Tr
                        key={index}
                        className="cursor-pointer hover:bg-gray-200"
                        onMouseDown={onMouseDownHandler}
                        onMouseMove={onMouseMoveHandler}
                        onClick={(e) => navigateToInboundForecastGroup(e, inboundForecastGroup.purchaseOrderReference)}
                    >
                        <Td>
                            <div className="flex items-center">
                                {inboundForecastGroup.reference}
                                {inboundForecastGroup.isNeedsAttention && (
                                    <Tooltip content="Needs attention">
                                        <div>
                                            <PiWarningFill className="ml-2 mb-0.5 -mr-8 text-red-500 w-5 h-5" />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </Td>
                        <Td>
                            {inboundForecastGroup.supplier.name}
                        </Td>
                        <Td className="text-right">
                            {inboundForecastGroup.inboundForecasts.length}
                        </Td>
                        <Td className="text-right">
                            {formatToDutchDateTime(inboundForecastGroup.montaCreated)}
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
}
