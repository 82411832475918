import { SalesOrder, Address } from "types/sales-order";
import { display } from "utils/pricing";
import StoreLogo from "./StoreLogo";
import { PiWarningFill } from "react-icons/pi";
import { Tooltip } from "@material-tailwind/react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'Components';
import { FaLocationDot } from "react-icons/fa6";
import { useClickableNavigate } from "utils/PathConstants";
import {formatToDutchDate} from "../../utils/dates";

export default function SalesOrderTable({ salesOrders }: { salesOrders: SalesOrder[] }) {
    const {
        onMouseDownHandler,
        onMouseMoveHandler,
        navigateToSalesOrder,
    } = useClickableNavigate();

    const shippingAddressLine = (address: Address): string => {
        return `${address.street} ${address.houseNumber}${address.houseNumberAddition ? `-${address.houseNumberAddition}` : ''}, ${address.postalCode} ${address.city}, ${address.countryCode}`;
    }

    return (
        <>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Store</Th>
                        <Th>Order #</Th>
                        <Th>Name</Th>
                        <Th>Shipping Address</Th>
                        <Th className="text-right">Lines</Th>
                        <Th className="text-right">Total (incl)</Th>
                        <Th className="text-right">Created</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {salesOrders.length === 0 && (
                        <Tr>
                            <Td colSpan={7}>
                                No sales orders found
                            </Td>
                        </Tr>
                    )}
                    {salesOrders.map((salesOrder, index) => (
                        <Tr
                            key={index}
                            className="cursor-pointer hover:bg-gray-50"
                            onClick={(e) => navigateToSalesOrder(e, salesOrder.reference.mage)}
                            onMouseDown={onMouseDownHandler}
                            onMouseMove={onMouseMoveHandler}
                        >
                            <Td className="w-14 min-w-14">
                                <StoreLogo store={salesOrder.store} />
                            </Td>
                            <Td>
                                <div className="flex items-center">
                                    {salesOrder.reference.mage}

                                    {salesOrder.isNeedsAttention && (
                                        <Tooltip content="Needs attention">
                                            <div>
                                                <PiWarningFill className="ml-2 mb-0.5 -mr-8 text-red-500 w-5 h-5" />
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                            </Td>
                            <Td>{salesOrder.customer.firstName + ' ' + salesOrder.customer.lastName}</Td>
                            <Td className="whitespace-nowrap">
                                {shippingAddressLine(salesOrder.shipping.address)}
                                {salesOrder.shipping.pickupLocationCode && (
                                    <Tooltip content="Pickup point">
                                        <div className="ml-1 inline-block">
                                            <FaLocationDot />
                                        </div>
                                    </Tooltip>
                                )}
                            </Td>
                            <Td className="text-right">
                                {salesOrder.items.length}
                            </Td>
                            <Td className="text-right whitespace-nowrap">
                                {display(salesOrder.billing.price.totalPrice.value)}
                            </Td>
                            <Td className="text-right whitespace-nowrap">
                                {formatToDutchDate(salesOrder.meta.createdAt)}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    );
}
