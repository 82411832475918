import { Meta } from "types/shared";

export interface MontaOrder {
    mongoId: string;
    blocked: boolean;
    blockedMessage: string;
    comment: string | null;

    webshopOrderId: string;
    internalWebshopOrderId: string | null;
    reference: string | null;

    received: string;
    shipped: string | null;

    origin: MontaOriginEnum

    lines: MontaOrderLine[];
    family: any[];

    picking: boolean;
    picked: string | null;

    deliveryDateRequested: string | null;
    deliveryDate: string | null;

    plannedShipmentDate: string | null;
    shipOnPlannedShipmentDate: boolean;

    shipperCode: string | null;
    shipperDescription: string | null;

    trackAndTraceCode: string | null;
    trackAndTraceLink: string | null;

    profortoData: ProfortoEditableData;

    meta: Meta;
}

export interface MontaOrderLine {
    backorder: boolean;
    batchCode: string | null;
    description: string;
    hasBeenBackorder: boolean;
    isFlyer: boolean;
    occured: string;
    orderedQuantity: number;
    sku: string;
    ean: string | null;
    webshopOrderLineId: string | null;
}

export enum MontaOriginEnum {
    ProfortoNL = "Proforto_web_nl",
    ProfortoBE = "Proforto_web_be",
    ProfortoBol = "Proforto_bol_nl",
    EmmaFootwear = "Emmafootwear_web_nl",
    Tricorpstore = "Tricorpstore_web_nl",
    Personalisation = "Proforto_Personalisation"
}

export interface ProfortoEditableData {
    isBackOrder: boolean;
    isDeleted: boolean;
    calculatedDeliveryDate: string;
    initialDeliveryDate: string;

    events: OrderEvents;
}

export interface OrderEvents {
    postponed: OrderEventPostponed[];
    cancelled: OrderEventCancelled[]
}

export interface OrderEventPostponed {
    reason: string;
    occured: string;
    newDate: string;
    originalDate: string;
}

export interface OrderEventCancelled {
    author: string;
    note: string;
    occured: string;
    items: OrderEventCancelledItem[];
}

export interface OrderEventCancelledItem {
    webshopOrderLineId?: string;
    description: string;
    sku: string;
    ean: string | null;
    quantity: number;
}
