import moment from "moment";

export const formatToDutchDate = (date: Date | string | null): string | null => {
    if (!date) {
        return null;
    }

    return moment(date).format('DD-MM-YYYY');
}

export const formatToDutchDateTime = (date: Date | string | null): string | null => {
    if (!date) {
        return null;
    }

    return moment(date).format('DD-MM-YYYY HH:mm');
}

export const formatToDutchDateTimeFromUtc = (date: Date | string | null): string | null => {
    if (!date) {
        return null;
    }

    return moment.utc(date).local().format('DD-MM-YYYY HH:mm');
}

export const sortByDateDesc = (a: string, b: string): number => {
    return new Date(b).getTime() - new Date(a).getTime();
}

export const sortByDateAsc = (a: string, b: string): number => {
    return new Date(a).getTime() - new Date(b).getTime();
}


