import { ForecastEvents, InboundForecast } from "types/inbound-forecast-group";
import { Checkbox, Table, Thead, Tbody, Tr, Th, Td } from 'Components'
import { Tooltip } from "@material-tailwind/react";
import { usePermissions } from "Contexts/PermissionsContext";
import {formatToDutchDate} from "../../utils/dates";
import { useClickableNavigate } from "utils/PathConstants";

type Props = {
    products: InboundForecast[];
    selectedProducts: InboundForecast[];
    toggleSelectedProduct: (inboundForecastId: number, checked: boolean) => void
    selectAllProducts: () => void
    deselectAllProducts: () => void
    inboundEvents: ForecastEvents
};

export default function InboundForecastGroupProductTable({ products, selectedProducts, toggleSelectedProduct, selectAllProducts, deselectAllProducts, inboundEvents }: Props) {
    const {
        onMouseDownHandler,
        onMouseMoveHandler,
        navigateToSku,
    } = useClickableNavigate();

    const { isAllowed, Permissions } = usePermissions();

    return (
        <Table>
            <Thead>
                <Tr>
                    {isAllowed(Permissions.CANCEL_INBOUND_FORECASTS) && (
                        <Th className="w-4">
                            {selectedProducts.length === products.length ? (
                                <Tooltip content="Deselect all" placement="bottom" followCursor={true}>
                                    <div>
                                        <Checkbox
                                            onChange={deselectAllProducts}
                                            checked={true}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip content="Select all" placement="bottom" followCursor={true}>
                                    <div>
                                        <Checkbox
                                            onChange={selectAllProducts}
                                            checked={false}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </Th>
                    )}
                    <Th className="whitespace-nowrap">#</Th>
                    <Th>Sku</Th>
                    <Th>Ean</Th>
                    <Th>Display Name</Th>
                    <Th>Expected Delivery Date</Th>
                    <Th>Amount Received</Th>
                </Tr>
            </Thead>
            <Tbody>
                {products.length === 0 && (
                    <Tr>
                        <Td colSpan={7}>
                            No Products found
                        </Td>
                    </Tr>
                )}
                {products.map((product, index) => (
                    <Tr key={`${index}-${product.inboundForecastId}`}>
                        {isAllowed(Permissions.CANCEL_INBOUND_FORECASTS) && (
                            <Td className="w-4">
                                <Checkbox
                                    onChange={checked => toggleSelectedProduct(product.inboundForecastId, checked)}
                                    checked={!!selectedProducts.find((selectedProduct) => selectedProduct.inboundForecastId === product.inboundForecastId)}
                                />
                            </Td>
                        )}
                        <Td className="w-4 text-right">{product.lineNumber}</Td>
                        <Td>
                            <div
                                className="hover:underline cursor-pointer"
                                onMouseDown={onMouseDownHandler}
                                onMouseMove={onMouseMoveHandler}
                                onClick={(e) => navigateToSku(e, product.sku)}
                            >
                                {product.sku}
                            </div>
                        </Td>
                        <Td>{product.ean}</Td>
                        <Td>{product.description}</Td>
                        {/* Fallback for older Inbounds that still rely on deliveryDate */}
                        <Td>{formatToDutchDate(product.expectedDeliveryDate ?? product.deliveryDate)}</Td>
                        <Td>{`${product.quantityReceived}/${product.quantity}`}</Td>
                    </Tr>
                ))}

                {products.length > 0 && (
                    <Tr>
                        <Td colSpan={7}>
                            Cancelled Items
                        </Td>
                    </Tr>
                )}

                {inboundEvents.removed.map((event, index) => (
                    <Tr
                        key={index}
                        className=" text-red-500"
                    >
                        <Td></Td>
                        <Td></Td>
                        <Td>
                            <div
                                className="hover:underline cursor-pointer"
                                onMouseDown={onMouseDownHandler}
                                onMouseMove={onMouseMoveHandler}
                                onClick={(e) => navigateToSku(e, event.sku)}
                            >
                                {event.sku}
                            </div>
                        </Td>
                        <Td>{event.ean}</Td>
                        <Td>{event.description}</Td>
                        <Td>-</Td>
                        <Td>{event.quantity} Cancelled</Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
}
