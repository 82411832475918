import moment from "moment";
import { Stock } from "types/stock";
import { SupplierAsOption } from "types/supplier";
import { Table, Thead, Tbody, Tr, Th, Td } from 'Components';
import { Tooltip } from "@material-tailwind/react";
import { useClickableNavigate } from "utils/PathConstants";
import {formatToDutchDate} from "../../utils/dates";

export default function StockTable({ stocks, suppliers }: { stocks: Stock[], suppliers: SupplierAsOption[] }) {
    const {
        onMouseDownHandler,
        onMouseMoveHandler,
        navigateToSku,
    } = useClickableNavigate();

    return (
        <>
            <Table>
                <Thead>
                    <Tr>
                        <Th className="text-[10px]">Name</Th>
                        <Th className="text-[10px]">SKU</Th>
                        <Th className="text-[10px]">EAN</Th>
                        <Th className="text-[10px]">Supplier</Th>
                        <Th className="text-[10px] text-right">All</Th>
                        <Th className="text-[10px] text-right">Warehouse</Th>
                        <Th className="text-[10px] text-right">Available</Th>
                        <Th className="text-[10px] text-right">Reserved</Th>
                        <Th className="text-[10px] text-right">Forcasted</Th>
                        <Th className="text-[10px] text-right">Min</Th>
                        <Th className="text-[10px] text-right">Step</Th>
                        <Th className="text-[10px] text-right">RTV?</Th>
                        <Th className="text-[10px] text-right">Updated</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {stocks.length === 0 && (
                        <Tr>
                            <Td colSpan={12}>
                                No stocks found
                            </Td>
                        </Tr>
                    )}
                    {stocks.map((stock, index) => (
                        <Tr
                            key={index}
                            className="cursor-pointer hover:bg-gray-200"
                            onMouseDown={onMouseDownHandler}
                            onMouseMove={onMouseMoveHandler}
                            onClick={(e) => navigateToSku(e, stock.product.sku)}
                        >
                            <Td>{stock.product.name}</Td>
                            <Td className="whitespace-nowrap">
                                {stock.product.sku}
                            </Td>
                            <Td>{stock.product.ean ?? '-'}</Td>
                            <Td>{suppliers.find(s => s.montaCode === stock.product.supplierMontaCode)?.name ?? '-'}</Td>
                            <Td className="text-right">{stock.amounts.all}</Td>
                            <Td className="text-right">{stock.amounts.warehouse}</Td>
                            <Td className="text-right">{stock.amounts.available}</Td>
                            <Td className="text-right">{stock.amounts.reserved}</Td>
                            <Td className="text-right">{stock.amounts.forecasted}</Td>
                            <Td className="text-right">{stock.amounts.minimum}</Td>
                            <Td className="text-right">{stock.amounts.step}</Td>
                            <Td className="text-right">
                                <Tooltip content={stock.excludeFromRtv ? 'Exclude item from RTV' : 'Include item in RTV'} placement="bottom">
                                    {stock.excludeFromRtv ? 'No' : 'Yes'}
                                </Tooltip>
                            </Td>
                            <Td className="whitespace-nowrap text-right">
                                {moment(stock.meta.updatedAt).isSame(moment(), 'day')
                                    ? moment(stock.meta.updatedAt).format('HH:mm[h]')
                                    : formatToDutchDate(stock.meta.updatedAt)
                                }
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    );
}
