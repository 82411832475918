import { GrSettingsOption } from "react-icons/gr";
import { useState } from "react";
import Modal from "Components/Library/Modal";
import Button from "Components/Library/Button";

type Props = {
    children: React.ReactElement | React.ReactElement[];
    onSave?: () => void;
}

export const CONFIGURED_LABEL_PRINTER_DESCRIPTION = 'ZDesigner';

const PageSettings = ({ children, onSave }: Props) => {
    const [open, setOpen] = useState(false);

    const handleSave = () => {
        if (onSave) {
            onSave();
        }
        setOpen(false);
    }

    return (
        <>
            <GrSettingsOption size={25} className="cursor-pointer" onClick={() => setOpen(true)} />
            {open && (
                <Modal
                    title="Settings"
                    onClose={() => setOpen(false)}
                    closeWithEscape
                >
                    <>
                        {children}
                        <div className="flex justify-end mt-4">
                            <Button onClick={handleSave}>
                                Save
                            </Button>
                        </div>
                    </>
                </Modal>
            )}
        </>
    )
}

export default PageSettings;
